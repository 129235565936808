<template>
  <td class="serial-port-info-cell" :data-id="dataItem.Id">
    <div
      class="operation-btn"
      :class="{ 'is-active': showPopup }"
      ref="popupBtnRef"
      @click="togglePopup"
    >
      <SvgIcon icon="list" />
    </div>
    <Popup
      :show="showPopup"
      :anchor="'popupBtnRef'"
      :popup-class="'popup'"
      :animate="false"
    >
      <div class="popup-row mb-2 serial-port-title">3CGSU08 串接埠資訊</div>
      <div class="popup-row serial-port-info">
        Fa0/3 <SvgIconFixedColor icon="port-use-status-1" /> [DES-3828] Port 1/1
      </div>
      <div class="popup-row serial-port-info">
        Fa0/24
        <SvgIconFixedColor icon="port-use-status-1" /> [2950_237.pixis.com]
        Fa0/24
      </div>
    </Popup>
  </td>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import useToggle from '@/composable/useToggle'
import { Popup } from '@progress/kendo-vue-popup'

export default defineComponent({
  components: {
    Popup
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { show: showPopup, toggle: togglePopup } = useToggle()
    const popupBtnRef = ref('')
    return {
      showPopup,
      togglePopup,
      popupBtnRef
    }
  }
})
</script>

<style scoped lang="scss">
.serial-port-info-cell {
  text-align: center;
}
</style>
