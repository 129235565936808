
import { defineComponent, onMounted, ref, watch } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInSwitchInfo from '@/composable/useGridInSwitchInfo'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import PortInfo from './PortInfo.vue'
import { Popup } from '@progress/kendo-vue-popup'

export default defineComponent({
  components: {
    GridWrapper,
    GridPageInfo,
    Grid,
    Dialog,
    PortInfo,
    Popup
  },
  setup() {
    // Port 孔位圖相關
    // 群組 array
    const createGroups = (arr, perGroup) => {
      const groupNums = Math.ceil(arr.length / perGroup)
      return new Array(groupNums)
        .fill('')
        .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
    }
    // port 狀態 0~4
    // 0: 綠色, 1: 紅色, 2: 淺色, 3: 深色
    const portStatus = [0, 1, 2, 3]
    // 初始 24 個 port array
    const portArray = Array(24)
      .fill(undefined)
      .map(() => portStatus[Math.floor(Math.random() * portStatus.length)])
    // 8 個一組
    const groupedPortArray = createGroups(portArray, 8)
    console.log(groupedPortArray)

    // 孔位圖顯示
    const { show: showPortGraph, toggle: togglePortGraph } = useToggle()

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInSwitchInfo()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // Dialog 相關
    // Port 使用狀況
    const { show: showPortInfo, toggle: togglePortInfo } = useToggle()

    // Popup 相關
    const { show: showIpPopup, toggle: toggleIpPopup } = useToggle()
    const popupBtnRef = ref('')

    onMounted(() => {
      showPortGraph.value = true
    })

    watch(showPortInfo, () => {
      // 打開 Port Dialog 時，強制關閉 showIpPopup
      showIpPopup.value = false
    })

    return {
      // Port 孔位圖相關
      groupedPortArray,
      showPortGraph,
      togglePortGraph,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      pageChangeHandler,
      // Dialog 相關
      showPortInfo,
      togglePortInfo,
      popupBtnRef,
      showIpPopup,
      toggleIpPopup
    }
  }
})
