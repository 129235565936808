<template>
  <td class="switch-name-cell">
    <div class="d-flex align-items-center">
      <span>{{ dataItem.SwitchName }}</span>
      <KendoTooltip id="tooltip" title="查看 Switch 資訊" :position="'right'">
        <div class="operation-btn" @click="$emit('toggleSwitchDialog')">
          <SvgIcon icon="dot-more" class="dot-icon" />
        </div>
      </KendoTooltip>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
