import { ref, markRaw } from 'vue'

// Cells
import Status from '../components/Grid/Cell/ProbePage/Status.vue'

// 區域列表中使用 Kendo Grid
const useGridInPortInfo = () => {
  // grid 資料
  const dataItems = ref([])

  // Header 欄位
  const columns = ref([
    {
      field: 'Status',
      title: '上線狀態',
      width: '92px',
      cell: markRaw(Status)
    },
    {
      field: 'MAC',
      title: 'MAC',
      width: '145px'
    },
    {
      field: 'HostName',
      title: '主機名稱',
      width: '117px'
    },
    {
      field: 'Ipv4',
      title: 'IPv4',
      width: '133px'
    },
    {
      field: 'Ipv6',
      title: 'IPv6',
      width: '200px'
    },
    {
      field: 'Usage',
      title: '設備用途',
      width: '200px'
    },
    {
      field: 'LoginPath',
      title: '登入方式',
      width: '145px'
    },
    {
      field: 'Username',
      title: '帳號',
      width: '145px'
    },
    {
      field: 'PasswordLogic',
      title: '密碼邏輯',
      width: '145px'
    },
    {
      field: 'Admin',
      title: 'admin',
      width: '145px'
    },
    {
      field: 'ExternalSourceIndex',
      title: '外部資料索引',
      width: '200px'
    }
  ])

  // 生成假資料
  const createRandomData = count => {
    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        Status: 'online',
        MAC: '005056A232C8',
        HostName: 'REMOTE-1',
        Ipv4: '192.168.10.199',
        Ipv6: '2001:b030:2133:80a::10:202',
        Usage: 'Release Test Server Main',
        LoginPath: 'RDP(網域)',
        Username: 'Adminintrator',
        PasswordLogic: '1aB',
        Admin: 'admin',
        ExternalSourceIndex: '--'
      }))
  }

  return {
    columns,
    dataItems,
    createRandomData
  }
}

export default useGridInPortInfo
