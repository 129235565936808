
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    iconName: String,
    text: String,
    disabled: Boolean
  },
  setup() {
    return {}
  }
})
