<template>
  <div class="control-btn" :class="{ 'is-disabled': disabled }">
    <SvgIconFixedColor :icon="iconName" class="main-icon" />
    {{ text }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    iconName: String,
    text: String,
    disabled: Boolean
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss"></style>
