import { ref, computed, markRaw } from 'vue'

// Cells
import UseState from '../components/Grid/Cell/SwitchPage/SwitchInfoDialog/UseState.vue'
import PortName from '../components/Grid/Cell/SwitchPage/SwitchInfoDialog/PortName.vue'
import Upload from '../components/Grid/Cell/SwitchPage/SwitchInfoDialog/Upload.vue'
import Download from '../components/Grid/Cell/SwitchPage/SwitchInfoDialog/Download.vue'
import Operation from '../components/Grid/Cell/SwitchPage/SwitchInfoDialog/Operation.vue'

// Switch 資訊 - Port 列表中使用 Kendo Grid
const useGridInSwitchInfo = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)

  // Header 欄位
  const columns = ref([
    {
      field: 'UseState',
      title: '狀態',
      width: '55px',
      cell: markRaw(UseState)
    },
    {
      field: 'PortName',
      title: 'Port 名稱',
      width: '200px',
      cell: markRaw(PortName)
    },
    {
      field: 'Upload',
      title: '上傳流量',
      width: '168px',
      cell: markRaw(Upload)
    },
    {
      field: 'Download',
      title: '下載流量',
      width: '168px',
      cell: markRaw(Download)
    },
    {
      field: 'Bandwidth',
      title: '頻寬',
      width: '65px'
    },
    {
      field: 'VlanId',
      title: 'VLAN ID',
      width: '85px'
    },
    {
      field: 'Note',
      title: '備註',
      width: 'auto'
    },
    {
      field: 'Operation',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation)
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    return dataItemsWithPage
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const useState = [0, 1, 2, 3] // 0: 空白, 1: 串接, 2: 對外, 3: 多人
    const upflow = [
      {
        rate: 50,
        text: '10 MB'
      },
      {
        rate: 30,
        text: '10 MB'
      },
      {
        rate: 10,
        text: '10 MB'
      },
      {
        rate: 90,
        text: '10 MB'
      },
      {
        rate: 95,
        text: '10 MB'
      }
    ]
    const downflow = [
      {
        rate: 15,
        text: '10 MB'
      },
      {
        rate: 7,
        text: '10 MB'
      }
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        UseState: useState[Math.floor(Math.random() * useState.length)],
        PortName: 'Gi1/0/2',
        Upload: upflow[Math.floor(Math.random() * upflow.length)],
        Download: downflow[Math.floor(Math.random() * downflow.length)],
        Bandwidth: '100 M',
        VlanId: '-',
        Note: '切記不可更動',
        Operation: '操作'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    createRandomData,
    pageChangeHandler
  }
}

export default useGridInSwitchInfo
