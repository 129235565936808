// Switch 列表

// 1. TreeView 相關
const treeViewData = [
  {
    id: 0,
    name: '全部',
    isParent: true,
    icons: [],
    count: 2392,
    expanded: true,
    childrens: [
      {
        id: 1,
        name: 'Kevin Office',
        isParent: false,
        icons: ['tree-i-site'],
        count: 1340
      },
      {
        id: 2,
        name: 'Taipei Office',
        isParent: true,
        icons: ['tree-i-site'],
        count: 1020,
        expanded: true,
        childrens: [
          {
            id: 3,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            childrens: [
              {
                id: 4,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 43
              }
            ]
          },
          {
            id: 5,
            name: 'R&D Department',
            isParent: true,
            icons: ['tree-i-network'],
            count: 1020,
            expanded: true,
            childrens: [
              {
                id: 6,
                name: '192.168.67.X',
                icons: ['tree-i-ip'],
                count: 40
              }
            ]
          },
          {
            id: 7,
            name: 'Test',
            isParent: false,
            icons: ['tree-i-network'],
            count: 32
          }
        ]
      }
    ]
  }
]

const treeViewSchema = {
  model: {
    id: 'id',
    hasChildren: 'isParent',
    children: 'childrens',
    schema: {
      model: {
        id: 'id',
        hasChildren: 'isParent'
      }
    }
  }
}

// 所有主機狀態 checkbox 選項
const switchCheckboxOptions = [
  { id: 'SwitchTimeout', name: '未回應', divider: false }
]
// 起始的主機狀態勾選 checkbox 選項
const switchInitialCheckedIds = ['SwitchTimeout']

// 可用的篩選條件：FilterUnitAdd.vue 元件
const keyWithOptions = [
  { key: '設備類型', options: ['D-Link', 'Cisco', 'Unknown'] },
  { key: '所屬區域', options: ['Taipei Office', 'Kaohsiung Office'] }
]
// 起始的篩選組合：FilterUnitDeletable.vue 元件
const initialFilterUnitList = [
  {
    id: 0,
    key: '設備類型',
    operator: '==',
    value: 'D-Link',
    options: ['D-Link', 'Cisco', 'Unknown'] // 值有選項，會渲染下拉選單
  }
]

export {
  treeViewData,
  treeViewSchema,
  switchCheckboxOptions,
  switchInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
}
