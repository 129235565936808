<template>
  <td class="probe-status-cell" :data-id="dataItem.Id">
    <span
      class="probe-state"
      :class="{
        'is-online': dataItem.Status == 'online',
        'is-backup': dataItem.Status == 'backup'
      }"
    ></span>
    {{ dataItem.Status == 'online' ? '服務中' : '備用' }}
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped></style>
