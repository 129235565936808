<template>
  <td class="use-state-cell" :data-id="dataItem.Id">
    <KendoTooltip
      v-if="dataItem.UseState > 0"
      id="tooltip"
      :title="getTooltipMsg(dataItem.UseState)"
      :position="'left'"
    >
      <SvgIconFixedColor :icon="`port-use-status-${dataItem.UseState}`" />
    </KendoTooltip>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import '@progress/kendo-ui/js/kendo.tooltip'
import { KendoTooltip } from '@progress/kendo-popups-vue-wrapper'

export default defineComponent({
  components: {
    KendoTooltip
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const getTooltipMsg = code => {
      const msg = ['', '串接', '對外', '多人']
      return msg[code]
    }
    return {
      getTooltipMsg
    }
  }
})
</script>

<style scoped>
.use-state-cell {
  text-align: center;
}
</style>
