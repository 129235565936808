<template>
  <td class="flow-rate-cell">
    <div class="rate-bar-wrapper">
      <div
        class="rate-bar"
        :class="{ overflow: dataItem.Download.rate >= 80 }"
        :style="{ width: `${dataItem.Download.rate}%` }"
      ></div>
      <span class="rate-text">{{ dataItem.Download.text }}</span>
    </div>
  </td>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    return {}
  }
})
</script>

<style scoped lang="scss">
.flow-rate-cell {
  padding: 16px 12px;
  position: relative;
}
</style>
