<template>
  <div class="port-info-wrapper">
    <h4 class="d-flex align-items-center justify-content-center">
      <img
        class="mr-3"
        :src="require(`@/assets/images/port-graph/port-2.svg`)"
      />
      Gi1/0/2
    </h4>

    <div class="port-info-list-container darken-list">
      <GridWrapper class="p-0 mt-4">
        <Grid
          :style="{ height: 'calc(100vh - 220px)' }"
          :columns="columns"
          :data-items="dataItems"
          :scrollable="'true'"
          :sortable="false"
          @toggleProbeSetting="toggleProbeSetting"
        ></Grid>
      </GridWrapper>
    </div>

    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import usGridInPortInfo from '@/composable/useGridInPortInfo'

export default defineComponent({
  components: {
    GridWrapper,
    Grid
  },
  setup() {
    // Grid 相關：
    const { columns, dataItems, createRandomData } = usGridInPortInfo()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    return {
      columns,
      dataItems
    }
  }
})
</script>

<style scoped></style>
