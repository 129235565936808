
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    field: String,
    title: String,
    sortable: [Boolean, Object]
  },
  setup() {
    return {}
  }
})
