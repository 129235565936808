<template>
  <div
    id="page-switch"
    class="main-list-view"
    :class="{ 'is-treeview-open': isTreeViewOpen }"
  >
    <!-- 左側：樹狀圖 -->
    <div class="tree-nav-container">
      <div class="close-btn" @click="isTreeViewOpen = false">
        <SvgIcon icon="arrow-right" />
      </div>
      <h3 class="tree-title">選取查詢範圍</h3>
      <div class="treeview-wrapper">
        <TreeView
          ref="treeViewRef"
          :data-source="localDataSource"
          :data-text-field="['name']"
          :template="template"
          :checkboxes="true"
          :checkboxes-check-children="true"
          @check="onTreeViewCheck"
        ></TreeView>
      </div>
    </div>
    <!-- 右側：列表容器 -->
    <div class="main-list-container">
      <div class="title-wrapper">
        <h2 class="title">Switch 列表</h2>
        <div
          class="toggle-treeview-btn"
          @click="isTreeViewOpen = !isTreeViewOpen"
        >
          <SvgIcon icon="tree" />
        </div>
      </div>
      <!-- 上：控制列容器 -->
      <ControlWrapper>
        <ControlButton
          iconName="plus"
          text="新增 Switch"
          id="register-switch"
        />
        <ControlButton iconName="garbage" text="刪除所有 Switch" />
        <ControlButton iconName="time" text="更新埠資訊" />
        <ControlButton iconName="update" text="排程設定" />
        <ControlDropdown
          iconName="download"
          text="匯出"
          id="export"
          :menuData="[{ name: '匯出 Excel' }, { name: '匯出 CSV' }]"
        />
      </ControlWrapper>

      <!-- 中：篩選列容器 -->
      <FilterWrapper>
        <FilterTextInput
          :placeholder="'查詢'"
          v-model.trim="textInput"
          @submitFilter="submitFilter"
        />
        <FilterUnitCheckbox
          :allChecked="switchAllChecked"
          :checkedIds="switchCheckIds"
          :keyName="'Switch 狀態'"
        >
          <label class="base-checkbox filter-checkbox">
            <input
              type="checkbox"
              v-model="switchAllChecked"
              @change="switchCheckAll"
            />
            <div class="custom-checkbox"></div>
            <span>全部</span>
          </label>
          <div class="divider"></div>
          <template v-for="option in switchCheckboxOptions" :key="option.id">
            <label class="base-checkbox filter-checkbox">
              <input
                type="checkbox"
                :value="option.id"
                v-model="switchCheckIds"
                @change="switchOnCheck"
              />
              <div class="custom-checkbox"></div>
              <span>{{ option.name }}</span>
            </label>
            <div class="divider" v-if="option.divider"></div>
          </template>
        </FilterUnitCheckbox>

        <FilterUnitDeletable
          v-for="filterUnit in filterUnitList"
          :filterUnit="filterUnit"
          :key="filterUnit.key"
          @updateFilterUnit="updateFilterUnit"
          @deleteFilterUnit="deleteFilterUnit"
        />

        <FilterUnitAdd
          :keyWithOptions="keyWithOptions"
          @addFilterUnit="addFilterUnit"
        />
        <div class="spacer"></div>
      </FilterWrapper>

      <!-- 下：表格容器 -->
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          id="kendo-grid"
          :style="{ height: 'calc(100vh - 240px)' }"
          :columns="columns"
          :data-items="result"
          :cell-render="'defaultTemplate'"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="pageChangeHandler"
          :scrollable="'true'"
          :selected-field="'selected'"
          @rowclick="onRowClick"
          :reorderable="true"
          @columnreorder="columnReorder"
          :sortable="true"
          :sort="sort"
          @sortchange="sortChangeHandler"
          :class="{ 'is-sortable': true }"
          @toggleSwitchDialog="toggleSwitchInfo"
        >
          <!-- 預設 cell render template，帶有 ['data-id'] -->
          <template v-slot:defaultTemplate="{ props }">
            <td :class="props.className" :data-id="props.dataItem.Id">
              {{ getNestedValue(props.field, props.dataItem) }}
            </td>
          </template>
        </Grid>
      </GridWrapper>
    </div>
    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container">
      <Dialog
        class="full-dialog"
        v-if="showSwitchInfo"
        :title="'Switch 資訊'"
        @close="toggleSwitchInfo"
      >
        <SwitchInfo />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, markRaw, onMounted, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInSwitch from '@/composable/useGridInSwitch'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import SwitchInfo from './SwitchInfo.vue'

import {
  treeViewData,
  treeViewSchema,
  switchCheckboxOptions,
  switchInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/switch'

export default defineComponent({
  components: {
    TreeView,
    ControlWrapper,
    ControlButton,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    SwitchInfo
  },
  setup() {
    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    const isTreeViewOpen = ref(true)

    // treeview template refs
    const treeViewRef = ref(null)
    // 取得 checked node 的 id
    const getCheckedNodeIds = (nodes, checkedNodes) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
          checkedNodes.push(nodes[i].id)
        }

        if (nodes[i].hasChildren) {
          getCheckedNodeIds(nodes[i].children.view(), checkedNodes)
        }
      }
    }
    // treeview onCheck 處理
    // CHECK: (https://www.telerik.com/kendo-vue-ui/components/treeview/checkboxes/)
    const onTreeViewCheck = () => {
      //@ts-ignore
      const treeView = treeViewRef.value.kendoWidget()
      // 被選取的 nodes
      const checkedNodes = []

      getCheckedNodeIds(treeView.dataSource.view(), checkedNodes)
      if (checkedNodes.length > 0) {
        console.log(
          'IDs of checked nodes in treeview: ' + checkedNodes.join(',')
        )
        // FIXME: 已取得 checkedNodes，可以用來作後續的查找
      } else {
        console.log('No nodes checked in treeview.')
      }
    }

    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 主機狀態 checkbox 篩選
    const {
      allChecked: switchAllChecked,
      checkedIds: switchCheckIds,
      onCheck: switchOnCheck,
      checkAll: switchCheckAll
    } = useFilterCheckbox(switchCheckboxOptions, switchInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('switchCheckIds: ' + switchCheckIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInSwitch()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // const onGridMenuOpen = e => {
    //   // Fires before a sub-menu or the ContextMenu is opened
    //   // CHECK: (https://www.telerik.com/kendo-vue-ui/components/layout/api/contextmenu/ContextMenu/#toc-open)
    //   // 取得此欄位 td 的 ['data-id']
    //   const Id = e.target.getAttribute('data-id')
    //   console.log('Id: ' + Id)
    //   // 觸發欄位 select
    //   e.target.click()
    // }

    const { show: showSwitchInfo, toggle: toggleSwitchInfo } = useToggle()

    return {
      // TreeView 相關
      template,
      localDataSource,
      isTreeViewOpen,
      treeViewRef,
      onTreeViewCheck,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：主機狀態 多選選項
      switchCheckboxOptions,
      switchCheckIds,
      switchAllChecked,
      switchOnCheck,
      switchCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      // Dialog 相關
      showSwitchInfo,
      toggleSwitchInfo
    }
  }
})
</script>

<style scoped></style>
