
import { defineComponent, onMounted } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import usGridInPortInfo from '@/composable/useGridInPortInfo'

export default defineComponent({
  components: {
    GridWrapper,
    Grid
  },
  setup() {
    // Grid 相關：
    const { columns, dataItems, createRandomData } = usGridInPortInfo()

    onMounted(() => {
      dataItems.value = createRandomData(16) as never[]
    })

    return {
      columns,
      dataItems
    }
  }
})
