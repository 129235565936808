
import { defineComponent, ref } from 'vue'
import useToggle from '@/composable/useToggle'
import { Popup } from '@progress/kendo-vue-popup'

export default defineComponent({
  components: {
    Popup
  },
  props: {
    field: String,
    dataItem: Object,
    format: String,
    className: String,
    columnIndex: Number,
    columnsCount: Number,
    rowType: String,
    level: Number,
    expanded: Boolean,
    editor: String
  },
  setup() {
    const { show: showPopup, toggle: togglePopup } = useToggle()
    const popupBtnRef = ref('')
    return {
      showPopup,
      togglePopup,
      popupBtnRef
    }
  }
})
