
import { defineComponent, markRaw, onMounted, ref } from 'vue'
import '@progress/kendo-ui/js/kendo.treeview'
import { TreeView } from '@progress/kendo-treeview-vue-wrapper'
import '@progress/kendo-ui/js/kendo.data'
// import { HierarchicalDataSource } from '@progress/kendo-datasource-vue-wrapper'
import ControlWrapper from '@/components/Control/ControlWrapper.vue'
import ControlButton from '@/components/Control/ControlButton.vue'
import ControlDropdown from '@/components/Control/ControlDropdown.vue'
import FilterWrapper from '@/components/Filter/FilterWrapper.vue'
import FilterTextInput from '@/components/Filter/FilterTextInput.vue'
import FilterUnitCheckbox from '@/components/Filter/FilterUnitCheckbox.vue'
import useFilterCheckbox from '@/composable/useFilterCheckbox'
import FilterUnitDeletable from '@/components/Filter/FilterUnitDeletable.vue'
import FilterUnitAdd from '@/components/Filter/FilterUnitAdd.vue'
import useFilterUnitList from '@/composable/useFilterUnitList'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInSwitch from '@/composable/useGridInSwitch'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import SwitchInfo from './SwitchInfo.vue'

import {
  treeViewData,
  treeViewSchema,
  switchCheckboxOptions,
  switchInitialCheckedIds,
  keyWithOptions,
  initialFilterUnitList
} from '@/mock/data/switch'

export default defineComponent({
  components: {
    TreeView,
    ControlWrapper,
    ControlButton,
    ControlDropdown,
    FilterWrapper,
    FilterTextInput,
    FilterUnitCheckbox,
    FilterUnitDeletable,
    FilterUnitAdd,
    GridWrapper,
    Grid,
    GridPageInfo,
    Dialog,
    SwitchInfo
  },
  setup() {
    // TreeView 相關
    // 注意！目前先使用 localDataSource 作法，待後端 API 完成請使用 remote HierarchicalDataSource

    /* template 目的為依據 item.icons 欄位渲染 icon，例如：<span class="tree-icon tree-i-site"></span>
     * .tree-i-* 須額外定義，檔案位置： '@/assets/styles/global/modify/kendo/_treeview.scss'
     */
    const template =
      '# for (i = 0; i < item.icons.length; i++ ) { #' +
      '<span class="tree-icon ' +
      '#: item.icons[i] #' +
      '"></span>' +
      '# } #' +
      '#: item.name #' +
      '<span class="child-count">' +
      '#: Number(item.count).toLocaleString() #' + // 1000 -> "1,000"
      '</span>'

    const localDataSource = markRaw(
      // eslint-disable-next-line
        new kendo.data.HierarchicalDataSource({
        data: treeViewData,
        schema: treeViewSchema
      })
    )

    const isTreeViewOpen = ref(true)

    // treeview template refs
    const treeViewRef = ref(null)
    // 取得 checked node 的 id
    const getCheckedNodeIds = (nodes, checkedNodes) => {
      for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].checked) {
          checkedNodes.push(nodes[i].id)
        }

        if (nodes[i].hasChildren) {
          getCheckedNodeIds(nodes[i].children.view(), checkedNodes)
        }
      }
    }
    // treeview onCheck 處理
    // CHECK: (https://www.telerik.com/kendo-vue-ui/components/treeview/checkboxes/)
    const onTreeViewCheck = () => {
      //@ts-ignore
      const treeView = treeViewRef.value.kendoWidget()
      // 被選取的 nodes
      const checkedNodes = []

      getCheckedNodeIds(treeView.dataSource.view(), checkedNodes)
      if (checkedNodes.length > 0) {
        console.log(
          'IDs of checked nodes in treeview: ' + checkedNodes.join(',')
        )
        // FIXME: 已取得 checkedNodes，可以用來作後續的查找
      } else {
        console.log('No nodes checked in treeview.')
      }
    }

    // 篩選相關
    // 文字輸入
    const textInput = ref('')

    // 主機狀態 checkbox 篩選
    const {
      allChecked: switchAllChecked,
      checkedIds: switchCheckIds,
      onCheck: switchOnCheck,
      checkAll: switchCheckAll
    } = useFilterCheckbox(switchCheckboxOptions, switchInitialCheckedIds)

    // 自訂篩選相關
    const {
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit
    } = useFilterUnitList(initialFilterUnitList)

    // 提交篩選
    const submitFilter = () => {
      // FIXME: 這裡提供了文字輸入、勾選、自訂篩選組的值，可以做後續組裡
      console.log('textInput: ' + textInput.value)
      console.log('switchCheckIds: ' + switchCheckIds.value)
      console.log('filterUnitList: ' + filterUnitList.value)
    }

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler
    } = useGridInSwitch()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // cell default template 取得此欄位 fieldName 對應的值
    const getNestedValue = (fieldName, dataItem) => {
      const path = fieldName.split('.')
      let data = dataItem
      path.forEach(p => {
        data = data ? data[p] : undefined
      })

      return data
    }

    // const onGridMenuOpen = e => {
    //   // Fires before a sub-menu or the ContextMenu is opened
    //   // CHECK: (https://www.telerik.com/kendo-vue-ui/components/layout/api/contextmenu/ContextMenu/#toc-open)
    //   // 取得此欄位 td 的 ['data-id']
    //   const Id = e.target.getAttribute('data-id')
    //   console.log('Id: ' + Id)
    //   // 觸發欄位 select
    //   e.target.click()
    // }

    const { show: showSwitchInfo, toggle: toggleSwitchInfo } = useToggle()

    return {
      // TreeView 相關
      template,
      localDataSource,
      isTreeViewOpen,
      treeViewRef,
      onTreeViewCheck,
      // 篩選相關：文字輸入
      textInput,
      submitFilter,
      // 篩選相關：主機狀態 多選選項
      switchCheckboxOptions,
      switchCheckIds,
      switchAllChecked,
      switchOnCheck,
      switchCheckAll,
      // 篩選相關：自訂篩選
      keyWithOptions,
      filterUnitList,
      updateFilterUnit,
      deleteFilterUnit,
      addFilterUnit,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      sort,
      createRandomData,
      pageChangeHandler,
      onRowClick,
      columnReorder,
      sortChangeHandler,
      getNestedValue,
      // Dialog 相關
      showSwitchInfo,
      toggleSwitchInfo
    }
  }
})
