import { ref, computed, markRaw } from 'vue'
import { orderBy } from '@progress/kendo-data-query'
// Cells
import SwitchName from '../components/Grid/Cell/SwitchPage/SwitchName.vue'
import Operation from '../components/Grid/Cell/SwitchPage/Operation.vue'
import OperationHeader from '../components/Grid/Cell/SwitchPage/OperationHeader.vue'
import OnlineMAC from '../components/Grid/Cell/SwitchPage/OnlineMAC.vue'
import OnlineMACHeader from '../components/Grid/Cell/SwitchPage/OnlineMACHeader.vue'
import IP from '../components/Grid/Cell/SwitchPage/IP.vue'
import SerialPortInfo from '../components/Grid/Cell/SwitchPage/SerialProtInfo.vue'

// 主機列表中使用 Kendo Grid
const useGridInSwitch = () => {
  // grid 資料
  const dataItems = ref([])
  // 分頁用
  const pageable = {
    buttonCount: 1,
    pageSizes: [16, 32, 128, 255],
    type: 'input',
    info: false
  }
  const skip = ref(0)
  const take = ref(16)
  // 排序用
  const sort = ref([])
  // 選擇
  const selectedID = ref(0)
  const onRowClick = e => {
    console.log('onRowClick Id: ' + e.dataItem.Id)
    selectedID.value = e.dataItem.Id
  }
  // Header 欄位
  const columns = ref([
    {
      field: 'SwitchName',
      title: 'Switch 名稱',
      width: '190px',
      cell: markRaw(SwitchName)
    },
    {
      field: 'Operate',
      title: '操作',
      width: '117px',
      cell: markRaw(Operation),
      headerCell: markRaw(OperationHeader)
    },
    {
      field: 'IP',
      title: 'IP',
      width: '150px',
      cell: markRaw(IP)
    },
    {
      field: 'Site',
      title: '所屬區域',
      width: '140px'
    },
    {
      field: 'ProbeName',
      title: 'Probe 名稱',
      width: '117px'
    },
    {
      field: 'UpdatedTime',
      title: '更新時間',
      width: '170px'
    },
    {
      field: 'Brand',
      title: '設備類型',
      width: '100px'
    },
    {
      field: 'OnlineMAC',
      title: 'MAC 上線數',
      width: '110px',
      cell: markRaw(OnlineMAC),
      headerCell: markRaw(OnlineMACHeader)
    },
    {
      field: 'SerialProtInfo',
      title: '串接埠資訊',
      width: '100px',
      cell: markRaw(SerialPortInfo)
    },
    {
      field: 'Note',
      title: '備註',
      width: '300px'
    }
  ])
  // 手動分頁
  const result = computed(() => {
    const dataItemsWithPage = dataItems.value.slice(
      skip.value,
      take.value + skip.value
    )
    const dataItemsWithPageAndSort = orderBy(dataItemsWithPage, sort.value)
    return dataItemsWithPageAndSort.map(item => ({
      ...item,
      selected: item.Id === selectedID.value
    }))
  })
  // 總資料數
  const total = computed(() => {
    return dataItems.value ? dataItems.value.length : 0
  })

  // 生成假資料
  const createRandomData = count => {
    const ipv4 = ['192.168.187.111', '192.168.137.11', '192.168.4.13']

    const operation = [
      { isDeletable: true },
      { isDeletable: true },
      { isDeletable: false }
    ]

    return Array(count)
      .fill({})
      .map((_, idx) => ({
        Id: idx + 1, // Unique Id for select
        SwitchName: 'SwitchName',
        Operation: operation[Math.floor(Math.random() * operation.length)],
        IP: ipv4[Math.floor(Math.random() * ipv4.length)],
        Site: 'San Jose Office',
        ProbeName: '192.168.56.182',
        UpdatedTime: '2020/12/29 15:52:50',
        Brand: 'Unknown',
        OnlineMAC: '777',
        SerialProtInfo: 'icon',
        Note: '備註'
      }))
  }

  const pageChangeHandler = e => {
    skip.value = e.page.skip
    take.value = e.page.take
  }

  // columns 重新排序
  const columnReorder = options => {
    columns.value = options.columns
  }

  // sort 處理
  const sortChangeHandler = e => {
    console.log('sort')
    sort.value = e.sort
  }

  return {
    columns,
    dataItems,
    pageable,
    skip,
    take,
    result,
    total,
    sort,
    createRandomData,
    pageChangeHandler,
    onRowClick,
    columnReorder,
    sortChangeHandler
  }
}

export default useGridInSwitch
