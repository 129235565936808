<template>
  <div class="switch-info-wrapper">
    <div class="switch-control-wrapper">
      <button class="btn btn-sm switch-btn">更新埠的資訊</button>
      <button
        class="btn btn-sm switch-btn"
        :class="{ 'is-highlight': !showPortGraph }"
        @click="togglePortGraph"
      >
        {{ showPortGraph ? '隱藏圖示' : '顯示圖示' }}
      </button>
    </div>
    <h5 class="text-center title-row">HP 1810-24G Switch</h5>
    <div class="text-center ip-row">
      <span>IP: 192.168.187.11</span>
      <span class="operation-btn" ref="popupBtnRef" @click="toggleIpPopup">
        <SvgIcon icon="dot-more" />
      </span>
      <Popup
        :show="showIpPopup"
        :anchor="'popupBtnRef'"
        :style="{ zIndex: 10003 }"
        :popup-class="'popup'"
        :animate="false"
      >
        <div class="popup-row mb-3">
          <span class="key">VLAN ID</span><span class="value">-</span>
        </div>
        <div class="spacer"></div>
        <div class="popup-row mt-2">
          <span class="key">管理的 IP</span
          ><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
        <div class="popup-row">
          <span class="key"></span><span class="value">192.168.80.254</span>
        </div>
      </Popup>
    </div>
    <!-- 上：Port 孔位圖容器 -->
    <div class="port-graph-wrapper" v-if="showPortGraph">
      <!-- 八個為一組 -->
      <div
        class="port-group"
        v-for="(group, idx) in groupedPortArray"
        :key="idx"
      >
        <!-- 單孔 -->
        <span
          class="single-port"
          v-for="(portStatus, index) in group"
          :key="index"
        >
          <img
            :class="{ 'is-reverse': index > 3 }"
            :src="require(`@/assets/images/port-graph/port-${portStatus}.svg`)"
          />
        </span>
      </div>
    </div>
    <!-- 下：表格容器 -->
    <div class="port-list-container darken-list">
      <GridWrapper>
        <GridPageInfo :skip="skip" :take="take" :total="total" />
        <Grid
          :style="{ height: 'calc(100vh - 401px)' }"
          :columns="columns"
          :data-items="result"
          :pageable="pageable"
          :skip="skip"
          :take="take"
          :total="total"
          @pagechange="pageChangeHandler"
          :scrollable="'true'"
          :sortable="false"
          @togglePortInfo="togglePortInfo"
        />
      </GridWrapper>
    </div>
    <!-- 隱藏：Dialog 容器 -->
    <div class="dialog-container">
      <Dialog
        v-if="showPortInfo"
        class="full-dialog is-nested"
        :title="'Port 使用狀況'"
        @close="togglePortInfo"
      >
        <PortInfo />
      </Dialog>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from 'vue'
import GridWrapper from '@/components/Grid/GridWrapper.vue'
import GridPageInfo from '@/components/Grid/GridPageInfo.vue'
import { Grid } from '@progress/kendo-vue-grid'
import useGridInSwitchInfo from '@/composable/useGridInSwitchInfo'
import { Dialog } from '@progress/kendo-vue-dialogs'
import useToggle from '@/composable/useToggle'
import PortInfo from './PortInfo.vue'
import { Popup } from '@progress/kendo-vue-popup'

export default defineComponent({
  components: {
    GridWrapper,
    GridPageInfo,
    Grid,
    Dialog,
    PortInfo,
    Popup
  },
  setup() {
    // Port 孔位圖相關
    // 群組 array
    const createGroups = (arr, perGroup) => {
      const groupNums = Math.ceil(arr.length / perGroup)
      return new Array(groupNums)
        .fill('')
        .map((_, i) => arr.slice(i * perGroup, (i + 1) * perGroup))
    }
    // port 狀態 0~4
    // 0: 綠色, 1: 紅色, 2: 淺色, 3: 深色
    const portStatus = [0, 1, 2, 3]
    // 初始 24 個 port array
    const portArray = Array(24)
      .fill(undefined)
      .map(() => portStatus[Math.floor(Math.random() * portStatus.length)])
    // 8 個一組
    const groupedPortArray = createGroups(portArray, 8)
    console.log(groupedPortArray)

    // 孔位圖顯示
    const { show: showPortGraph, toggle: togglePortGraph } = useToggle()

    // Grid 相關
    const {
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      createRandomData,
      pageChangeHandler
    } = useGridInSwitchInfo()

    onMounted(() => {
      dataItems.value = createRandomData(255) as never[]
    })

    // Dialog 相關
    // Port 使用狀況
    const { show: showPortInfo, toggle: togglePortInfo } = useToggle()

    // Popup 相關
    const { show: showIpPopup, toggle: toggleIpPopup } = useToggle()
    const popupBtnRef = ref('')

    onMounted(() => {
      showPortGraph.value = true
    })

    watch(showPortInfo, () => {
      // 打開 Port Dialog 時，強制關閉 showIpPopup
      showIpPopup.value = false
    })

    return {
      // Port 孔位圖相關
      groupedPortArray,
      showPortGraph,
      togglePortGraph,
      // Grid 相關
      columns,
      dataItems,
      pageable,
      skip,
      take,
      result,
      total,
      pageChangeHandler,
      // Dialog 相關
      showPortInfo,
      togglePortInfo,
      popupBtnRef,
      showIpPopup,
      toggleIpPopup
    }
  }
})
</script>

<style scoped></style>
